<template>
  <div>
    
  <v-data-table
    :headers="headers"
    :items="voucherseries.data"
    sort-by="prog_code"
    class="elevation-1"
    dense
    :search="search"
    :items-per-page="1000"
	hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Voucher Series Maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
		<vue-excel-xlsx
			:data="voucherseries.data"
			:columns="columnHeaders"
			:file-name="module_name"
			>
			<v-btn icon alt class="mt-2" :color="voucherseries.data.length=== 0 ? 'gray' : 'primary'" :disabled="voucherseries.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
        <v-icon
			color="grey"
			class="mr-2"
			small
			v-if="role_access < 4"
		>
			mdi-delete-off
		</v-icon>
		<v-icon
			small
			v-else
			color="red"
			@click="deleteItem(item)"
		>
			delete
		</v-icon>
    </template>
		<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(voucherseries.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(voucherseries.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{voucherseries.current_page}} ( {{voucherseries.from}} - {{voucherseries.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!voucherseries.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!voucherseries.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
  </v-data-table>
  <create-voucher-series v-if="role_access > 1"></create-voucher-series>
  </div>
</template>

<script>
import createVoucherSeries from './createVoucherSeries.vue';
import { mapGetters } from 'vuex';


export default {
	components: {
		'create-voucher-series': createVoucherSeries
	},
	data(){
		return{
			module_name: 'Voucher Series',
			role_access: 1,
			search: ''
		};
	},
	computed: {
		...mapGetters({
			voucherseries: 'voucherseries/voucherseries',
			headers: 'voucherseries/headers',
			columnHeaders: 'voucherseries/columnHeaders',
			editedItem: 'voucherseries/editedItem',
			defaultItem: 'voucherseries/defaultItem',
			dialog: 'voucherseries/dialog',
			editedIndex: 'voucherseries/editedIndex',
			valid: 'voucherseries/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('voucherseries/getVoucherSeries');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		deleteItem (item) {
			this.$store.dispatch('voucherseries/setEditedIndex',this.voucherseries.data.indexOf(item));
			this.$swal.fire({
				title: 'Delete Voucher Series?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('voucherseries/setisLoading',true);
					this.$store.dispatch('voucherseries/deleteVoucherSeries',item);
				}
			});
		},
		changeNextData() {
			var nextPage = this.voucherseries.current_page + 1;
			this.$store.dispatch('voucherseries/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.voucherseries.current_page - 1;
			this.$store.dispatch('voucherseries/getDataPages', prevPage);
		}
	},
};
</script>

