<template>
	<div class="home">
		<table-voucher></table-voucher>
	</div>
</template>

<script>
import tableVoucher from '@/components/pages/maint/voucherseries/tableVoucherSeries.vue';

export default {
	components: {
		'table-voucher': tableVoucher,
	}
};
</script>

<style>

</style>
